.annotation-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  max-height: 820px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 12px rgba(71, 77, 81, 0.2);
  overflow: hidden;

  .container-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
  }

  .container-body {
    min-height: calc(100% - 83px);
    overflow: scroll;
  }

  .container-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 21px;
    height: 33px;
    font-size: 12px;
    font-weight: 500;
    border-top: 1px solid #dddddd;
    align-items: center;
  }
}

.active-bleeding-list-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 400px;
  height: 95%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 12px rgba(71, 77, 81, 0.2);
  overflow-y: auto;
  max-height: 820px;


  .container-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
  }

  .container-body {
    display: flex;
    min-height: calc(100% - 83px);

    .active-bleeding-annotation-list-body {
      overflow-y: auto;
    }    
  }

  .container-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 21px;
    height: 33px;
    font-size: 12px;
    font-weight: 500;
    border-top: 1px solid #dddddd;
    align-items: center;
  }
}

.ab-intervention-list-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 550px;
  height: 95%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 12px rgba(71, 77, 81, 0.2);
  overflow: hidden;
  max-height: 820px;

  overflow-y: auto;

  .container-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #dddddd;

    .list-tab {
      &:first-child {
        width: 70%;
      }
    }
  }

  .container-body {
    display: flex;
    min-height: calc(100% - 83px);
    width: 100%;
  }

  .container-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 21px;
    height: 33px;
    font-size: 12px;
    font-weight: 500;
    border-top: 1px solid #dddddd;
    align-items: center;
  }
}