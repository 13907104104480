.admin-member-page {
  display: flex;
  flex-direction: column;
  margin: 30px 60px;
  box-sizing: border-box;
  height: 100%;

  .admin-member-page-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .admin-member-page-p {
      font-size: 3rem;
      font-weight: 900;
      margin: 15px;
    }

    Button {
      display: flex;
      justify-content: center;
      align-self: center;
    }
  }

  // .admin-member-page-table {
  //   max-height: 800px;
  //   overflow-y: auto;
  // }
}
